import { Box, Button, Container, Divider, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
	const navigate = useNavigate();

	const redirect = async () => {
		navigate('/');
	};

	return (
		<>
			<Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
				<Paper
					sx={{
						display: 'flex',
						flexDirection: 'column',
						mb: 4,
					}}>
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', pt: 2, pl: 2, pr: 2, pb: 2, margin: 'auto' }}>
						<Typography variant='h6'>Whoops! Something went wrong!</Typography>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', pt: 2, pl: 2, pr: 2, pb: 2, margin: 'auto' }}>
						<Button onClick={redirect}>Go back to the main page</Button>
					</Box>
					<>
						<Divider />
					</>
				</Paper>
			</Container>
		</>
	);
};

const myCanvas = document.getElementById('my-canvas');
// const myContext = myCanvas?.getContext('2d');

export default ErrorPage;
