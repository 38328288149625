import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { BugReport, Description, Dns, Group, Insights, ProductionQuantityLimits, QrCodeScanner, ReceiptLong, PrecisionManufacturing } from '@mui/icons-material';
import { RoleGate } from './components/General/RoleGate';
import { StaffRole } from './models/StaffRole';

export const MenuListItems = (
	<React.Fragment>
		<ListItemButton href='/'>
			<ListItemIcon>
				<DashboardIcon />
			</ListItemIcon>
			<ListItemText primary='Dashboard' />
		</ListItemButton>
		<ListItemButton href='checkins'>
			<ListItemIcon>
				<QrCodeScanner />
			</ListItemIcon>
			<ListItemText primary='Check-Ins' />
		</ListItemButton>
		<ListItemButton href='journeys'>
			<ListItemIcon>
				<Group />
			</ListItemIcon>
			<ListItemText primary='Journeys' />
		</ListItemButton>
		<ListItemButton href='orders'>
			<ListItemIcon>
				<ShoppingCartIcon />
			</ListItemIcon>
			<ListItemText primary='Orders' />
		</ListItemButton>
		<ListItemButton href='receipts'>
			<ListItemIcon>
				<ReceiptLong />
			</ListItemIcon>
			<ListItemText primary='Receipts' />
		</ListItemButton>
		<RoleGate requiredRole={StaffRole.ROOT}>
			<ListItemButton href='accuracy'>
				<ListItemIcon>
					<Insights />
				</ListItemIcon>
				<ListItemText primary='Accuracy' />
			</ListItemButton>
		</RoleGate>
		<RoleGate requiredRole={StaffRole.ROOT}>
			<ListItemButton href='overrides'>
				<ListItemIcon>
					<ProductionQuantityLimits />
				</ListItemIcon>
				<ListItemText primary='Product Overrides' />
			</ListItemButton>
		</RoleGate>
		<ListItemButton href='manual'>
			<ListItemIcon>
				<Description />
			</ListItemIcon>
			<ListItemText primary='Store Manual' />
		</ListItemButton>
		<ListItemButton href='issue'>
			<ListItemIcon>
				<BugReport />
			</ListItemIcon>
			<ListItemText primary='Issue Log' />
		</ListItemButton>
		<ListItemButton href='system-health'>
			<ListItemIcon>
				<Dns />
			</ListItemIcon>
			<ListItemText primary='System Health' />
		</ListItemButton>
		<ListItemButton href='products'>
			<ListItemIcon>
				<PrecisionManufacturing />
			</ListItemIcon>
			<ListItemText primary='Products' />
		</ListItemButton>
	</React.Fragment>
);
