import React from 'react';
import { Box, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import PlanogramProduct from '../models/PlanogramProduct';
import API from '../datalayer/API';
import { useLocation, useNavigate } from 'react-router-dom';

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ProductGondolaPage = () => {
	const query = useQuery();
	const gondolaId = query.get('gondolaId') ?? '';
	const [shelves, setShelves] = React.useState(new Map<number, Array<PlanogramProduct>>());
	const navigate = useNavigate();

	console.log(shelves);

	const loadData = async () => {
		try {
			const planogramProducts = await API.getGondolaDetails(gondolaId);
			const shelves = new Map<number, Array<PlanogramProduct>>();

			for (const planogramProduct of planogramProducts) {
				const shelfId = planogramProduct.shelfId;
				let shelf = shelves.get(shelfId);

				if (!shelf) {
					shelf = new Array<PlanogramProduct>();
				}

				shelf.push(planogramProduct);
				shelves.set(shelfId, shelf);
			}

			setShelves(shelves);
		} catch (error) {
			console.warn("Couldn't load gondola details, redirecting to system health page");
			navigate('/error');
		}
	};

	React.useEffect(() => {
		loadData();
	}, []);

	return (
		<>
			<Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
				<Paper
					sx={{
						display: 'flex',
						flexDirection: 'column',
						mb: 4,
					}}>
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', pt: 2, pl: 2, pr: 2, pb: 2 }}>
						<Typography variant='h6'>{`Gondola #${gondolaId}`}</Typography>
						<Typography variant='body1'>{''}</Typography>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', pt: 2, pl: 2, pr: 2, pb: 2 }}>
						<Grid container spacing={2}>
							{Array.from(shelves).map(([shelfId, products]) => {
								return (
									<Grid item xs={12}>
										<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', pt: 2, pl: 2, pr: 2, pb: 2 }}>
											{shelfId}
											<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', pt: 2, pl: 2, pr: 2, pb: 2 }}>
												<Grid container spacing={2}>
													{products.map((product) => {
														return (
															<Grid item xs={Math.round(12 * product.binSize)}>
																<Box
																	sx={{
																		display: 'flex',
																		flexDirection: 'column',
																		justifyContent: 'space-between',
																		alignItems: 'center',
																		pt: 2,
																		pl: 2,
																		pr: 2,
																		pb: 2,
																	}}>
																	<Typography variant='h6'>{`${product.productName}`}</Typography>
																</Box>
															</Grid>
														);
													})}
												</Grid>
											</Box>
										</Box>
										<Divider />
									</Grid>
								);
							})}
						</Grid>
					</Box>
					<>
						<Divider />
					</>
				</Paper>
			</Container>
		</>
	);
};

const myCanvas = document.getElementById('my-canvas');
// const myContext = myCanvas?.getContext('2d');

export default ProductGondolaPage;
