import AccuracyTestingOverviewDTO, { AccuracyTestingJourneyInterface, AccuracyTestingManualReview, CartItemWithProductCode } from '../models/AccuracyTestingModels';
import AiFiEvent from '../models/AiFiEvent';
import { AiFiProduct } from '../models/AiFiProduct';
import AlertOccurrence from '../models/AlertOccurrence';
import CheckinEvent from '../models/CheckinEvent';
import DashboardJourney, { CustomerJourneyState, JourneyType } from '../models/DashboardJourney';
import DashboardOrder, { BopState, OrderType, PaymentState } from '../models/DashboardOrder';
import { EventType } from '../models/EventType';
import ManualChapter from '../models/ManualChapter';
import ManualOptions from '../models/ManualOptions';
import PlanogramProduct from '../models/PlanogramProduct';
import PricingOverride from '../models/PricingOverride';
import Product from '../models/Product';
import ReceiptListItemDTO from '../models/ReceiptListItemDTO';
import ScanEvent from '../models/ScanEvent';
import { SimulatedEventType } from '../models/SimulatedEventType';
import TapAppInEvent from '../models/TapAppInEvent';

export default class API {
	static config = window.location.hostname.includes('prd')
		? {
				environment: 'prd',
				aisBaseUrl: 'https://dashboard.prd.shopandgo.aldi.co.uk',
				sprykerBaseUrl: 'https://zed.de.aldi-pltfrm-prod.cloud.spryker.toys',
				aifiBaseUrl: 'https://console.aifi.com/retailers/7c4ea51a-53c6-44ee-8dbb-d66d885ef401/orders',
				receiptPortalBaseUrl: 'https://shopandgo.aldi.co.uk',
				useLocalStorageAuth: false,
		  }
		: {
				environment: 'dev',
				aisBaseUrl: 'https://dashboard.dev.shopandgo.aldi.co.uk',
				sprykerBaseUrl: 'https://zed.de.aldi-pltfrm-stage.cloud.spryker.toys',
				aifiBaseUrl: 'https://console.aifi.com/retailers/8b7df34c-0da8-47c7-85ad-50437d0f629b/orders',
				receiptPortalBaseUrl: 'https://lab.shopandgo.aldi.co.uk',
				useLocalStorageAuth: window.location.hostname.includes('localhost'),
		  };

	static getLocalStorageAuthHeader: any = () => {
		return this.config.useLocalStorageAuth ? { Authorization: `Bearer ${localStorage.getItem('dev-access-token')}` } : {};
	};

	static async getAccuracyOverview(startDate: Date, endDate: Date): Promise<AccuracyTestingOverviewDTO> {
		const query = new URLSearchParams();

		query.append('startDate', startDate.toISOString());
		query.append('endDate', endDate.toISOString());

		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/accuracy/overview?${query}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});

		if (res.status !== 200) {
			throw new Error('Error getting accuracy overview');
		}
		const data = await res.json();
		return data;
	}

	static async getAccuracyJourneyDetails(journeyId: number): Promise<AccuracyTestingJourneyInterface> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/accuracy/${journeyId}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});

		if (res.status !== 200) {
			throw new Error('Error getting accuracy overview');
		}
		const data = await res.json();
		return data;
	}

	static async addAccuracyJourneyReview(journeyId: number, reviews: Array<AccuracyTestingManualReview>): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/accuracy/${journeyId}/reviews`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
			body: JSON.stringify(reviews),
		});
		if (res.status !== 201) {
			throw new Error('Error ading accuracy review');
		}
	}

	static async updateAccuracyJourneyScannedItems(journeyId: number, scannedItems: Array<CartItemWithProductCode>): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/accuracy/${journeyId}/scannedItems`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
			body: JSON.stringify(scannedItems),
		});
		if (res.status !== 201) {
			throw new Error('Error updating accuracy scanned items');
		}
	}

	static async invalidateAccuracyJourney(journeyId: number): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/accuracy/${journeyId}/invalidate`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 201) {
			throw new Error('Error invalidating accuracy journey');
		}
	}

	static async retryAllOrders(): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/retry`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 200) {
			throw new Error('Error retrying order');
		}
		const data = await res.json();
		return data;
	}

	static async retryOrder(orderReceiptId: string): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/retry/${orderReceiptId}`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 201) {
			throw new Error('Error retrying order');
		}
		const data = await res.json();
		return data;
	}

	static async setJourneyCancelled(journeyCustomerId: number, cancelled: boolean): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/journey/cancel`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
			body: JSON.stringify({
				journeyCustomerId,
				cancelled,
			}),
		});
		if (res.status !== 204) {
			throw new Error('Error setting order cancelled');
		}
	}

	static async simulateAppInEvent(sub: string): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/journey/simulation`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
			body: JSON.stringify({
				sub,
				eventType: SimulatedEventType.APP_IN,
			}),
		});
		if (res.status !== 204) {
			throw new Error('Error simulating app in event');
		}
	}

	static async simulateJourneyEvent(journeyCustomerId: number, eventType: SimulatedEventType): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/journey/simulation`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
			body: JSON.stringify({
				journeyCustomerId,
				eventType,
			}),
		});
	}

	static async findReceipts(params: {
		date?: string;
		transactionId?: string;
		transactionValue?: number;
		firstDigits?: string;
		lastDigits?: string;
		expiryDate?: string;
		journeyType?: JourneyType;
		offset?: number;
		limit?: number;
	}): Promise<Array<ReceiptListItemDTO>> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/receipt/find`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
			body: JSON.stringify(params),
		});
		if (res.status !== 200 && res.status !== 201) {
			throw new Error('Error finding receipts');
		}
		return await res.json();
	}

	static async getAgeRestrictedFlagRate(params: { startDate?: Date; endDate?: Date }): Promise<{ flaggedAmount: number; totalAmount: number }> {
		const query = new URLSearchParams();

		if (params.startDate) {
			query.append('startDate', params.startDate.toISOString());
		}

		if (params.endDate) {
			query.append('endDate', params.endDate.toISOString());
		}

		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/flagrate?${query}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});

		if (res.status !== 200) {
			throw new Error('Error getting age restricted flag rate');
		}
		const data = await res.json();
		return {
			flaggedAmount: data.flaggedAmount,
			totalAmount: data.totalAmount,
		};
	}

	static async getCustomerLimit(): Promise<{ currentCustomerAmount: number; maxCustomerAmount: number }> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/customerlimit`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});

		if (res.status !== 200) {
			throw new Error('Error getting customer limit');
		}
		const data = await res.json();
		return {
			currentCustomerAmount: data.currentCustomerAmount,
			maxCustomerAmount: data.maxCustomerAmount,
		};
	}

	static async getEvents(params: {
		amount?: number | undefined;
		lastId?: string | undefined;
		journeyCustomerId?: number | undefined;
		aifiSessionId?: string | undefined;
		entryCode?: string | undefined;
		exitCode?: string | undefined;
		sub?: string | undefined;
		type?: EventType | undefined;
		types?: Array<EventType> | undefined;
	}): Promise<Array<AiFiEvent | ScanEvent | CheckinEvent | TapAppInEvent>> {
		const query = new URLSearchParams();

		if (params.amount) {
			query.append('limit', String(params.amount));
		}

		if (params.lastId) {
			query.append('lastId', params.lastId);
		}

		if (params.type) {
			query.append('type', params.type);
		}

		if (params.types) {
			query.append('types', params.types.join(','));
		}

		if (params.sub) {
			query.append('sub', params.sub);
		}

		if (params.journeyCustomerId) {
			query.append('journeyCustomerId', String(params.journeyCustomerId));
		}

		if (params.aifiSessionId) {
			query.append('aifiSessionId', String(params.aifiSessionId));
		}

		if (params.entryCode) {
			query.append('entryCode', params.entryCode);
		}

		if (params.exitCode) {
			query.append('exitCode', params.exitCode);
		}

		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/storeevents?${query}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 200) {
			throw new Error('Error getting events');
		}
		const data = await res.json();
		return data;
	}

	static async getOrderAmount(params: { paymentState?: PaymentState; bopState?: BopState; type?: OrderType; startDate?: Date; endDate?: Date }): Promise<number> {
		const query = new URLSearchParams();

		if (params.startDate) {
			query.append('startDate', params.startDate.toISOString());
		}

		if (params.endDate) {
			query.append('endDate', params.endDate.toISOString());
		}

		if (params.paymentState) {
			query.append('paymentState', params.paymentState);
		}

		if (params.bopState) {
			query.append('bopState', params.bopState);
		}

		if (params.type) {
			query.append('type', params.type);
		}

		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/amount/orders?${query}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 200) {
			throw new Error('Error getting order amount');
		}
		const data = await res.json();
		return data.amount;
	}

	static async getJourneyAmount(params: { state?: CustomerJourneyState; startDate?: Date; endDate?: Date; type?: JourneyType; sub?: string }): Promise<number> {
		const query = new URLSearchParams();

		if (params.startDate) {
			query.append('startDate', params.startDate.toISOString());
		}

		if (params.endDate) {
			query.append('endDate', params.endDate.toISOString());
		}

		if (params.state) {
			query.append('state', params.state);
		}

		if (params.type) {
			query.append('type', params.type);
		}

		if (params.sub) {
			query.append('sub', params.sub);
		}

		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/amount/journeys?${query}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 200) {
			throw new Error('Error getting journey amount');
		}
		const data = await res.json();
		return data.amount;
	}

	static async getFailedEventsAmount(params: { type?: EventType; startDate?: Date; endDate?: Date }): Promise<number> {
		const query = new URLSearchParams();

		if (params.startDate) {
			query.append('startDate', params.startDate.toISOString());
		}

		if (params.endDate) {
			query.append('endDate', params.endDate.toISOString());
		}

		if (params.type) {
			query.append('type', params.type);
		}

		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/amount/failedEvents?${query}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 200) {
			throw new Error('Error getting failed events amount');
		}
		const data = await res.json();
		return data.amount;
	}

	static async getJourney(key: string, value: string): Promise<DashboardJourney> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/journey?${key}=${value}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 200) {
			throw new Error('Error getting journey');
		}
		const data = await res.json();
		return data;
	}

	static async getLiveReceipt(journeyCustomerId: number): Promise<Array<AiFiProduct>> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/journey/live-receipt?customerId=${journeyCustomerId}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 200) {
			return [];
		}
		const data = await res.json();
		return data;
	}

	static async getOrdersForJourney(journeyCustomerId: number): Promise<Array<DashboardOrder>> {
		const query = new URLSearchParams();

		if (journeyCustomerId) {
			query.append('journeyCustomerId', String(journeyCustomerId));
		}

		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/orders?${query}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 200) {
			throw new Error('Error getting orders');
		}
		const data = await res.json();
		return data;
	}

	static async getOrders(params: {
		limit?: number;
		lastId?: string;
		paymentState?: PaymentState;
		bopState?: BopState;
		type?: OrderType;
		startDate?: Date;
		endDate?: Date;
		aifiOrderId?: string;
	}): Promise<Array<DashboardOrder>> {
		const query = new URLSearchParams();

		if (params.limit) {
			query.append('limit', String(params.limit));
		}

		if (params.lastId) {
			query.append('lastId', params.lastId);
		}

		if (params.aifiOrderId) {
			query.append('aifiOrderId', params.aifiOrderId);
		}

		if (params.startDate) {
			query.append('startDate', params.startDate.toISOString());
		}

		if (params.endDate) {
			query.append('endDate', params.endDate.toISOString());
		}

		if (params.paymentState) {
			query.append('paymentState', params.paymentState);
		}

		if (params.bopState) {
			query.append('bopState', params.bopState);
		}

		if (params.type) {
			query.append('type', params.type);
		}

		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/orders?${query}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 200) {
			throw new Error('Error getting journeys');
		}
		const data = await res.json();
		return data;
	}

	static async getJourneys(params: {
		limit?: number;
		lastId?: string;
		state?: CustomerJourneyState;
		type?: JourneyType;
		startDate?: Date;
		endDate?: Date;
		sub?: string;
	}): Promise<Array<DashboardJourney>> {
		const query = new URLSearchParams();

		if (params.limit) {
			query.append('limit', String(params.limit));
		}

		if (params.lastId) {
			query.append('lastId', params.lastId);
		}

		if (params.startDate) {
			query.append('startDate', params.startDate.toISOString());
		}

		if (params.endDate) {
			query.append('endDate', params.endDate.toISOString());
		}

		if (params.state) {
			query.append('state', params.state);
		}

		if (params.type) {
			query.append('type', params.type);
		}

		if (params.sub) {
			query.append('sub', params.sub);
		}

		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/journey/list?${query}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 200) {
			throw new Error('Error getting journeys');
		}
		const data = await res.json();
		return data;
	}

	static async login(username: string, password: string): Promise<{ accessToken: string; expiresAt: Date }> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/login`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
			body: JSON.stringify({
				username,
				password,
			}),
		});
		if (res.status !== 200) {
			throw new Error('Error logging in');
		}
		const data = await res.json();
		return {
			accessToken: data.accessToken,
			expiresAt: new Date(data.expiresAt),
		};
	}

	static async logout(): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/logout`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 204) {
			throw new Error('Error logging out');
		}
	}

	static async getOverrides(): Promise<Array<PricingOverride>> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/overrides`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});

		if (res.status !== 200) {
			throw new Error('Error getting overrides');
		}
		const data = await res.json();
		return data;
	}

	static async deleteOverride(originalBarcode: string): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/overrides/remove/${originalBarcode}`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});

		if (res.status !== 201) {
			throw new Error('Error deleting override');
		}
	}

	static async createOverride(override: PricingOverride): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/overrides/add`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
			body: JSON.stringify(override),
		});

		if (res.status !== 201) {
			throw new Error('Error creating override');
		}
	}

	static async getManual(): Promise<Array<ManualChapter>> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/manual`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});

		if (res.status !== 200) {
			throw new Error('Error getting manual');
		}
		const data = await res.json();
		return data;
	}

	static async getManualOptions(): Promise<ManualOptions> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/manual/options`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});

		if (res.status !== 200) {
			throw new Error('Error getting manual options');
		}
		const data = await res.json();
		return data;
	}

	static async createManualChapter(title: string): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/manual/chapter/create`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
			body: JSON.stringify({
				title,
			}),
		});

		if (res.status !== 201) {
			throw new Error('Error creating chapter');
		}
	}

	static async updateManualChapter(chapter: ManualChapter): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/manual/chapter/update`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
			body: JSON.stringify(chapter),
		});

		if (res.status !== 201) {
			throw new Error('Error updating chapter');
		}
	}

	static async createIssueReport(description: string): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/issue`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
			body: JSON.stringify({
				description,
			}),
		});

		if (res.status !== 201) {
			throw new Error('Error creating issue report');
		}
	}

	static async getAlertHistory(params: { limit?: number; lastId?: string }): Promise<Array<AlertOccurrence>> {
		const query = new URLSearchParams();

		if (params.limit) {
			query.append('limit', String(params.limit));
		}

		if (params.lastId) {
			query.append('lastId', params.lastId);
		}

		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/alerts/history?${query}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 200) {
			throw new Error('Error getting alert history');
		}
		const data = await res.json();
		return data;
	}

	static async getFiringAlerts(): Promise<Array<AlertOccurrence>> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/alerts/firing`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 200) {
			throw new Error('Error getting firing alerts');
		}
		const data = await res.json();
		return data;
	}

	static async isCheckoutBlocked(): Promise<boolean> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/block/checkout`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 200) {
			throw new Error('Error getting checkout blocked');
		}
		const data = await res.json();
		return data.blocked;
	}

	static async setCheckoutBlocked(blocked: boolean): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/block/checkout`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
			body: JSON.stringify({
				blocked,
			}),
		});
		if (res.status !== 204) {
			throw new Error('Error setting checkout blocked');
		}
	}

	static async isSprykerCustomerCheckBlocked(): Promise<boolean> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/block/spryker`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				...this.getLocalStorageAuthHeader(),
			},
		});
		if (res.status !== 200) {
			throw new Error('Error getting spryker customer check blocked');
		}
		const data = await res.json();
		return data.blocked;
	}

	static async setSprykerCustomerCheckBlocked(blocked: boolean): Promise<void> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/block/spryker`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
			body: JSON.stringify({
				blocked,
			}),
		});
		if (res.status !== 204) {
			throw new Error('Error setting spryker customer check blocked');
		}
	}

	static async getProductsMissingAnImage(): Promise<PlanogramProduct[]> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/product/defaultImages`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
		});

		if (res.status !== 200) {
			throw new Error('Error getting products');
		}

		const data = await res.json();
		return data;
	}

	static async getProductDetails(code: string): Promise<{ product: Product; planogramProduct: PlanogramProduct }> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/product/${code}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
		});

		if (res.status !== 200) {
			throw new Error('Error getting product details');
		}

		const data = await res.json();
		return data;
	}

	static async getGondolaDetails(gondolaId: string): Promise<Array<PlanogramProduct>> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/product/gondola/${gondolaId}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
		});

		if (res.status !== 200) {
			throw new Error('Error getting gondola details');
		}

		const data = await res.json();
		return data;
	}

	static async updateProduct(code: string): Promise<number> {
		const res = await fetch(`${API.config.aisBaseUrl}/staffApp/product/updateProduct`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...this.getLocalStorageAuthHeader(),
			},
			body: JSON.stringify({ code }),
		});

		if (res.status === 408) {
			return res.status;
		}

		if (res.status !== 204) {
			throw new Error('Update failed');
		}

		return 200;
	}
}
