import React, { useRef, useEffect } from 'react';
import storePlan from '../../assets/StorePlan.json';
import { Box, Button, Container, Paper } from '@mui/material';
import PlanogramMarker from '../../models/PlanogramMarker';

const StorePlan: React.FC<{ screenWidth: number; screenHeight: number; gondolaId: number | undefined }> = ({ screenWidth, screenHeight, gondolaId }) => {
	const imageRef = useRef(null);
	const [canvasWidth, setCanvasWidth] = React.useState(0);
	const [canvasHeight, setCanvasHeight] = React.useState(0);
	const [canvasX, setCanvasX] = React.useState(0);
	const [canvasY, setCanvasY] = React.useState(0);
	const [markers, setMarkers] = React.useState(new Map<number, PlanogramMarker>());

	const remCalc = (px: number | string, base: number = 16) => {
		const tempPx = `${px}`.replace('px', '');

		return (1 / base) * parseInt(tempPx);
	};

	const pixCalc = (rem: number | string, base: number = 16) => {
		const tempPx = `${rem}`.replace('rem', '');

		return (1 * base) / parseInt(tempPx);
	};

	useEffect(() => {
		const image = new Image();
		image.src = '/store_plan_2.svg';

		const im: any = imageRef.current;
		if (!im) {
			throw new Error('Failed to get context');
		} // Should never happen

		image.onload = () => {
			const rect = im.getBoundingClientRect();
			setCanvasX(rect.x);
			setCanvasY(rect.y);

			// let aspectRatio = image.width / image.height;
			let aspectRatio = storePlan.scale.scaleX / storePlan.scale.scaleY;
			setCanvasWidth(Number(screenWidth / 1.95));
			setCanvasHeight(Number(screenWidth / 1.95 / aspectRatio));

			if (gondolaId) {
				const { id, x, y } = storePlan.gondolas[gondolaId];
				const { scaleX, scaleY } = storePlan.scale;

				const coordX = canvasX + Number((x / storePlan.scale.scaleX) * canvasWidth) - pixCalc(1);
				const coordY = canvasY + Number((y / storePlan.scale.scaleY) * canvasHeight) - pixCalc(2);
				const marker: PlanogramMarker = { coordX, coordY, gondolaId };
				markers.set(gondolaId, marker);
				setMarkers(new Map(markers));
			} else {
				for (const gondola of storePlan.gondolas) {
					const gondolaId = gondola.id;
					const { id, x, y } = storePlan.gondolas[gondolaId];

					const coordX = canvasX + Number((x / storePlan.scale.scaleX) * canvasWidth) - pixCalc(1);
					const coordY = canvasY + Number((y / storePlan.scale.scaleY) * canvasHeight) - pixCalc(2);
					const marker: PlanogramMarker = { coordX, coordY, gondolaId };
					markers.set(gondolaId, marker);
					setMarkers(new Map(markers));
				}
			}
		};
	}, [gondolaId, canvasX, canvasY]);

	return (
		<>
			<img ref={imageRef} id='store-plan-image' src='/store_plan_2.svg' alt='Logo' width={canvasWidth} height={canvasHeight} style={{ zIndex: 1, position: 'absolute' }} />
			{Array.from(markers).map(([_, marker]) => {
				return (
					<Button
						key={gondolaId}
						href={`/gondola?gondolaId=${marker.gondolaId}`}
						style={{
							zIndex: 1,
							position: 'absolute',
							top: marker.coordY,
							left: marker.coordX,
							background: '#D70000',
							borderRadius: '100rem',
							fontSize: '0.86rem',
							minWidth: '2.00rem',
							minHeight: '1.00rem',
							textAlign: 'center',
							color: 'black',
							cursor: 'pointer',
						}}>
						{marker.gondolaId}
					</Button>
				);
			})}
			<Container maxWidth='xl' sx={{ zIndex: 1, overflow: 'hidden' }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						alignItems: 'center',

						width: canvasWidth,
						height: canvasHeight,
					}}></Box>
			</Container>
		</>
	);
};

export default StorePlan;
