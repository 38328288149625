import React from 'react';
import { Box, Button, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import PlanogramProduct from '../models/PlanogramProduct';
import useWindowDimensions from '../components/Product/WindowDimensions';
import API from '../datalayer/API';
import Product from '../models/Product';
import { useLocation, useNavigate } from 'react-router-dom';
import StorePlan from '../components/Product/StorePlan';

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

enum ProductUpdateState {
	NOT_STARTED = 0,
	SUCCESS = 1,
	FAILED = 2,
	TOO_MANY_REQUESTS = 3,
}

const ProductDetailsPage = () => {
	const query = useQuery();
	const [productDetails, setProductDetails] = React.useState<{ product: Product; planogramProduct: PlanogramProduct } | null>(null);
	const [updateState, setUpdateState] = React.useState(ProductUpdateState.NOT_STARTED);
	const { height, width } = useWindowDimensions();
	const navigate = useNavigate();

	const code = query.get('code') ?? '';

	const updateProduct = async (code: string) => {
		if (updateState !== ProductUpdateState.NOT_STARTED) return;

		console.log(`updating product with code ${code}`);
		try {
			const status = await API.updateProduct(code);
			if (status === 408) {
				setUpdateState(ProductUpdateState.TOO_MANY_REQUESTS);
			} else {
				setUpdateState(ProductUpdateState.SUCCESS);
			}
		} catch (error) {
			setUpdateState(ProductUpdateState.FAILED);
		}
	};

	const loadData = async () => {
		if (code == '') navigate('/products');

		try {
			const productDetails = await API.getProductDetails(code);
			setProductDetails(productDetails);
		} catch (error) {
			console.error(error);
			console.warn("Couldn't load product details, redirecting to system health page");
			navigate('/error');
		}
	};

	React.useEffect(() => {
		loadData();
	}, []);

	return (
		<>
			<Container maxWidth='xl' sx={{ mt: 4, mb: 4, zIndex: 1 }}>
				<Paper
					sx={{
						display: 'flex',
						flexDirection: 'column',
						mb: 4,
					}}>
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', pt: 2, pl: 2, pr: 2, pb: 2 }}>
						<Typography variant='h6'>{productDetails?.product?.scanDesc}</Typography>
						<Typography variant='body1'>{''}</Typography>
					</Box>
					<Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
						<Grid item xs={12} md={6} lg={6}>
							<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
								<Typography color='text.secondary' gutterBottom>
									Product Details
								</Typography>
								{productDetails?.product.productCode && (
									<Typography variant='body2' gutterBottom>
										Product Code: {productDetails?.product.productCode}
									</Typography>
								)}
								{productDetails?.product.description && (
									<Typography variant='body2' gutterBottom>
										Description: {productDetails?.product.description}
									</Typography>
								)}
								{productDetails?.product.upcs && (
									<>
										{productDetails?.product.upcs.map((upc: string) => {
											return (
												<Typography variant='body2' gutterBottom key={upc}>
													{upc}
												</Typography>
											);
										})}
									</>
								)}
								{productDetails?.product.ageRestriction && (
									<Typography variant='body2' gutterBottom>
										Age restricticed: {productDetails?.product.ageRestriction}
									</Typography>
								)}
								{productDetails?.product.productCode ? (
									<Button
										onClick={() => {
											updateProduct(productDetails?.product.productCode);
										}}
										variant='contained'
										color={updateState === ProductUpdateState.NOT_STARTED ? 'primary' : updateState === ProductUpdateState.SUCCESS ? 'success' : 'error'}
										sx={{ m: 2 }}>
										{updateState === ProductUpdateState.NOT_STARTED
											? 'Update Product'
											: updateState === ProductUpdateState.SUCCESS
											? 'Update Successful'
											: updateState === ProductUpdateState.FAILED
											? 'Update Failed'
											: 'Too many requests'}
									</Button>
								) : (
									<></>
								)}
							</Paper>
						</Grid>
					</Container>
					<>
						<Divider />
					</>
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', pt: 2, pl: 2, pr: 2, pb: 2 }}>
						{productDetails?.planogramProduct ? <StorePlan screenWidth={width} screenHeight={height} gondolaId={productDetails?.planogramProduct?.gondolaId} /> : <></>}
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default ProductDetailsPage;
