import PlanogramProduct from '../models/PlanogramProduct';
import Product from '../models/Product';
import { DELETE_STORE, SET_PLANOGRAM_PRODUCT, SET_PRODUCT } from './Types';

const INITIAL_STATE: {
	planogramProduct: PlanogramProduct | null;
	product: Product | null;
} = { planogramProduct: null, product: null };

export default function ProductReducer(state = INITIAL_STATE, action: { type: string; payload: any }) {
	switch (action.type) {
		case SET_PLANOGRAM_PRODUCT:
			return { ...state, planogramProduct: action.payload };
		case SET_PRODUCT:
			return { ...state, product: action.payload };
		case DELETE_STORE:
			return INITIAL_STATE;
		default:
			return state;
	}
}
