import React from 'react';
import API from '../datalayer/API';
import { Box, Button, Container, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import PlanogramProduct from '../models/PlanogramProduct';
import { useAppDispatch } from '../datalayer/hooks';
import { setPlanogramProduct } from '../datalayer/Actions';
import { useNavigate } from 'react-router-dom';

const ProductsPage = () => {
	const dispatch = useAppDispatch();
	const [products, setProducts] = React.useState<Array<PlanogramProduct>>(new Array<PlanogramProduct>());
	const navigate = useNavigate();

	const loadData = async () => {
		try {
			const products = await API.getProductsMissingAnImage();
			console.log(products);
			setProducts(products);
		} catch (error) {
			console.warn("Couldn't load products, redirecting to system health page");
			navigate('/error');
		}
	};

	const selectProduct = async (planogramProduct: PlanogramProduct) => {
		console.log(`selecting product ${JSON.stringify(planogramProduct)}`);
		dispatch(setPlanogramProduct(planogramProduct));
	};

	React.useEffect(() => {
		loadData();
	}, []);

	return (
		<>
			<Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
				<Paper
					sx={{
						display: 'flex',
						flexDirection: 'column',
						mb: 4,
					}}>
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', pt: 2, pl: 2, pr: 2, pb: 2 }}>
						<Typography variant='h6'>Products</Typography>
						<Typography variant='body1'>{''}</Typography>
					</Box>
					<>
						<Divider />
					</>
				</Paper>
				<Paper sx={{ width: '100%', mb: 2 }}>
					<TableContainer>
						<Table sx={{ minWidth: 650 }} aria-label='simple table'>
							<TableHead>
								<TableRow>
									<TableCell align='right'>AiFiProductId</TableCell>
									<TableCell align='right'>ProductBarcode</TableCell>
									<TableCell align='right'>ProductName</TableCell>
									<TableCell align='right'>GondolaId</TableCell>
									<TableCell align='right'>ShelfId</TableCell>
									<TableCell align='right'>BinSize</TableCell>
									<TableCell align='right'>Details</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{products?.map((product: PlanogramProduct) => (
									<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`${product.productBarcode}-${product.gondolaId}`}>
										<TableCell align='right'>{product.productId}</TableCell>
										<TableCell align='right'>{product.productBarcode}</TableCell>
										<TableCell align='right'>{product.productName}</TableCell>
										<TableCell align='right'>{product.gondolaId}</TableCell>
										<TableCell align='right'>{product.shelfId}</TableCell>
										<TableCell align='right'>{product.binSize}</TableCell>
										<TableCell align='right'>
											<Button
												variant='outlined'
												target='_blank'
												onClick={async () => {
													selectProduct(product);
												}}
												href={`/product?code=${product.productBarcode}`}>
												Details
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Container>
		</>
	);
};

export default ProductsPage;
